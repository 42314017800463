import { ReactNode, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import TH from './TH.component';
import TD from './TD.component';
import moreIcon from '../../assets/more-horizontal-svgrepo-com.svg';
import addIcon from '../../assets/add-svgrepo-com.svg';
import IconButton from '../IconButton.component';
import useEscapeKey from '../../hooks/useEscapeKey.hook';
import useOutsideClick from '../../hooks/useOutsideClick';
import Entity from '../../model/entity.model';

type Action = 'add' | 'edit' | 'delete' | 'roles' | string;

const EntityTable = ({
  title,
  headers,
  data,
  footer,
  growingColumnIndex,
  permissions,
}: {
  title: string;
  headers: string[];
  data?: { id: number; [key: string]: unknown }[];
  footer?: ReactNode;
  growingColumnIndex?: number;
  permissions: { [K in Action]?: <T extends Entity>(item: T) => boolean | undefined };
}) => {
  const { t } = useTranslation(['common']);
  const navigate = useNavigate();
  const location = useLocation();
  const [openMenuFor, setOpenMenuFor] = useState<number | undefined>(undefined);
  const menuRef = useRef<HTMLDivElement>(null);

  useEscapeKey(() => {
    setOpenMenuFor(undefined);
  });

  useOutsideClick(() => {
    setOpenMenuFor(undefined);
  }, menuRef);

  const actions = Object.entries(permissions ?? {}).filter(([key]) => key !== 'add');

  return (
    <div className="flex flex-col flex-1">
      <div className="flex justify-between items-center">
        <h2 className="py-4 text-3xl">{title}</h2>
        {permissions.add && (
          <IconButton
            src={addIcon}
            alt={t('iconsAlt.add', { context: location.pathname })}
            onClick={() => {
              navigate(`${location.pathname}/add`, { state: { background: location } });
              setOpenMenuFor(undefined);
            }}
          />
        )}
      </div>
      <table className="table-auto text-left border-collapse relative flex-1">
        <thead className="text-xl relative">
          <tr className="absolute inset-0 rounded-t-sm bg-slate-700" />
          <tr className="sticky">
            {headers.map((header, columnIndex) => (
              <TH
                key={header}
                className={`${growingColumnIndex === columnIndex ? 'w-full' : ''} whitespace-pre`}
              >
                {header}
              </TH>
            ))}
            {actions.length > 0 && <TH />}
          </tr>
        </thead>
        <tbody>
          {data?.map((item) => (
            <tr
              key={item.id}
              className="border-t border-slate-500 hover:cursor-pointer hover:bg-slate-600 hover:border-slate-200 hover:border-b first:border-t-transparent"
              onClick={(event) => {
                event.stopPropagation();
                navigate(`${location.pathname}/${item.id}`);
              }}
            >
              {Object.keys(item).map((key, columnIndex) => {
                if (key === 'id') {
                  return null;
                }
                const value = item[key];
                if (typeof value === 'string' || typeof value === 'number') {
                  return (
                    <TD
                      key={key.toString()}
                      className={growingColumnIndex === columnIndex ? 'w-full' : ''}
                    >
                      {value}
                    </TD>
                  );
                }
                return <TD key={key.toString()} />;
              })}
              {actions.length > 0 && (
                <TD
                  className="cursor-pointer hover:bg-slate-500 w-0 relative"
                  onClick={(event) => {
                    event.stopPropagation();
                    if (openMenuFor === item.id) {
                      setOpenMenuFor(undefined);
                    } else {
                      setOpenMenuFor(item.id);
                    }
                  }}
                >
                  <img
                    width={25}
                    height={25}
                    src={moreIcon}
                    alt={t('iconsAlt.more')}
                    className="max-w-none"
                  />

                  <div
                    ref={openMenuFor === item.id ? menuRef : undefined}
                    className={`${openMenuFor === item.id ? 'flex' : 'hidden'} bg-slate-50 flex-col items-stretch rounded-sm absolute right-0 top-100 z-10 p-1`}
                  >
                    {actions
                      .filter(([, permitter]) => permitter?.(item))
                      .map(([route]) => (
                        <Link
                          key={route}
                          onClick={() => {
                            setOpenMenuFor(undefined);
                          }}
                          to={`${location.pathname}/${route}/${item.id}`}
                          state={{ background: location }}
                          className="bg-none text-black border-none py-1 px-3 hover:bg-opacity-50 bg-opacity-0 bg-slate-900 text-center hover:text-black rounded-sm"
                        >
                          {t(route)}
                        </Link>
                      ))}
                  </div>
                </TD>
              )}
            </tr>
          ))}
        </tbody>
      </table>
      {footer}
    </div>
  );
};

export default EntityTable;
