import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import Modal from './Modal.component';
import TextButton from './TextButton.component';
import { useAppSelector } from '../store';
import { useDeleteDivisionMutation } from '../store/api/divisions.api';
import warning from '../assets/warning-1-svgrepo-com.svg';

const DeleteDivisionModal = () => {
  const { t } = useTranslation('divisions');
  const [modalActive, setModalActive] = useState(true);
  const [deleteDivision] = useDeleteDivisionMutation();
  const params = useParams();
  const { divisions } = useAppSelector((state) => state.divisionsSlice);
  const division = divisions?.find((d) => d.id === parseInt(params.id ?? '-1', 10));
  const [error, setError] = useState('');
  const context = 'delete';
  const shouldClose = !params.id || Number.isNaN(parseInt(params.id, 10)) || !division;
  useEffect(() => {
    if (shouldClose) {
      setModalActive(false);
    }
  }, [params, division]);

  if (shouldClose) {
    return null;
  }

  return (
    <Modal isActive={modalActive} header={t('modal.header', { context })}>
      <form
        className="flex flex-col justify-between flex-1"
        onSubmit={(event) => {
          event.preventDefault();
          // assignment on a separate row to avoid ts-expect-error affecting more than just the id
          // @ts-expect-error params is defined here, as per the assertion
          const id = parseInt(params.id, 10);
          deleteDivision(id)
            .catch(() => {
              setError(t('modal.error', { context }));
            })
            .finally(() => {
              setModalActive(false);
            });
        }}
      >
        <div className="flex flex-col gap-2">
          <div className="flex gap-2 justify-between">
            {t('modal.areYouSure', { context, name: division.name })}
            <img src={warning} alt={t('iconsAlt.warning')} height={24} width={24} />
            {error && <div className="text-rose-500">{error}</div>}
          </div>
        </div>
        <TextButton type="submit">{t('modal.submit', { context })}</TextButton>
      </form>
    </Modal>
  );
};

export default DeleteDivisionModal;
