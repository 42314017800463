import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useCookies } from 'react-cookie';
import TextInput from './TextInput.component';
import Modal from './Modal.component';
import TextButton from './TextButton.component';
import { useEditProfileMutation, useGetProfileQuery } from '../store/api/profile.api';
import { api } from '../store/api';
import { useLogoutMutation } from '../store/api/auth.api';
import User from '../model/user.model';

const ProfileModal = () => {
  const { t } = useTranslation('profile');

  const { data: profileData } = useGetProfileQuery(null) as { data: { user: User } };
  const [editProfile] = useEditProfileMutation();
  const [logout] = useLogoutMutation();

  const { user } = profileData ?? {};

  const [name, setName] = useState(user?.username);
  const [error, setError] = useState('');
  const [, , removeCookie] = useCookies(['refreshJWT', 'accessJWT']);
  const [modalActive, setModalActive] = useState(true);

  const dispatch = useDispatch();
  const shouldClose = !user;
  useEffect(() => {
    if (shouldClose) {
      setModalActive(false);
    }
  }, [shouldClose]);

  if (shouldClose) {
    return null;
  }

  return (
    <Modal
      isActive={modalActive}
      header={t('modal.header', { username: user?.username ?? '' })}
      footer={
        <TextButton
          onClick={() => {
            removeCookie('refreshJWT', { domain: import.meta.env.VITE_DOMAIN });
            removeCookie('accessJWT', { domain: import.meta.env.VITE_DOMAIN });
            logout()
              .then(() => {
                setModalActive(false);
              })
              .finally(() => {
                dispatch(api.util.resetApiState());
              });
          }}
        >
          {t('modal.logout')}
        </TextButton>
      }
    >
      <form
        className="flex flex-col justify-between flex-1"
        onSubmit={(event) => {
          event.preventDefault();
          editProfile({ username: name })
            .unwrap()
            .then(() => {
              setModalActive(false);
            })
            .catch(() => {
              setError(t('modal.error'));
            });
        }}
      >
        <TextInput label={t('modal.name')} onChange={setName} value={name} />
        {error && <div className="text-rose-500">{error}</div>}
        <TextButton type="submit">{t('modal.submit')}</TextButton>
      </form>
    </Modal>
  );
};

export default ProfileModal;
