import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '../store';
import { useLoginMutation, useRegisterMutation } from '../store/api/auth.api';
import TextButton from './TextButton.component';
import { useGetProfileQuery } from '../store/api/profile.api';
import TextInput from './TextInput.component';
import logo from '../assets/logo.svg';
import { setIdle } from '../store/authSlice';

type Variant = 'login' | 'register';

const AuthForm = ({ variant }: { variant: Variant }) => {
  const { t } = useTranslation('auth');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [emailFocused, setEmailFocused] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { status, message } = useAppSelector((state) => state.authSlice);
  const { user, status: profileStatus } = useAppSelector((state) => state.profileSlice);
  const [login] = useLoginMutation();
  const [register] = useRegisterMutation();

  useGetProfileQuery(null);

  let other = 'login';
  if (variant === 'login') {
    if (import.meta.env.VITE_ALLOW_REGISTER) {
      other = 'register';
    } else {
      other = '';
    }
  }

  const validateEmail = (inputEmail: string) => {
    // any string followed by @, followed by any string, followed by ., followed by any string
    const re = /\S+@\S+\.\S+/;
    return re.test(inputEmail);
  };

  useEffect(() => {
    if (user || status === 'succeeded') {
      dispatch(setIdle());
      navigate('/home');
    }
  }, [user, status]);

  return (
    <div className="flex flex-col inset-0 justify-center items-center h-full gap-y-10">
      <div className="flex-1 flex items-center justify-center">
        <img src={logo} alt={t('logo')} className="w-48 h-48" />
      </div>
      <div className="flex-2">
        <form
          className="card text-black flex flex-col gap-y-4"
          onSubmit={async (event) => {
            event.preventDefault();
            if (!validateEmail(email)) return;
            if (variant === 'login') {
              login({ email, password });
            } else {
              register({ email, password });
            }
          }}
        >
          {profileStatus === 'loading' ? (
            <div>{t('loading')}</div>
          ) : (
            <>
              <h1 className="text-3xl md:mx-10 text-center">{t('title', { context: variant })}</h1>
              <TextInput
                label={t('email')}
                onChange={setEmail}
                required
                value={email}
                type="email"
                onFocus={() => setEmailFocused(true)}
                onBlur={() => setEmailFocused(false)}
              />
              {email && !validateEmail(email) && !emailFocused && <p>{t('invalidEmail')}</p>}
              <TextInput
                label={t('password')}
                onChange={setPassword}
                required
                value={password}
                type="password"
              />
              <TextButton type="submit">
                {status === 'loading' ? t('loading') : t('submitButton')}
              </TextButton>
              {status === 'failed' && (
                <div className="text-rose-500 text-center">
                  {t(message ?? 'somethingWentWrong', { context: variant })}
                </div>
              )}
              {other && (
                <Link to={other} className="font-medium link text-center">
                  {t('link', { context: variant })}
                </Link>
              )}
              <Link to="/forgot" className="font-medium link text-center">
                {t('linkForgot')}
              </Link>
            </>
          )}
        </form>
      </div>
      <div className="flex-1" />
    </div>
  );
};

export default AuthForm;
