import { useEffect } from 'react';

const useScrollDisable = (active?: boolean) => {
  useEffect(() => {
    if (active) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = '';
    }
  }, [active]);
};

export default useScrollDisable;
