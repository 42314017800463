import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import SelectInput from './SelectInput.component';
import profile from '../assets/profile-default-svgrepo-com.svg';
import { useGetProfileDivisionsQuery } from '../store/api/profile.api';
import { useAppSelector } from '../store';

const Toolbar = () => {
  const { t } = useTranslation('common');
  const location = useLocation();
  const { data: divisions } = useGetProfileDivisionsQuery(null);
  const { selectedDivision } = useAppSelector((state) => state.profileSlice);
  const dispatch = useDispatch();
  return (
    <div className="bg-slate-700 p-2 px-4 flex flex-row-reverse items-center gap-5">
      <Link to="/profile" state={{ background: location.pathname }} className="mx-0.5">
        <img src={profile} height={36} width={36} alt={t('iconsAlt.profile')} />
      </Link>
      <SelectInput
        value={
          selectedDivision
            ? { value: selectedDivision?.id?.toString(), label: selectedDivision?.name }
            : undefined
        }
        options={
          divisions?.map((division) => {
            return { value: division.id.toString(), label: division.name };
          }) ?? []
        }
        onChange={(value) => {
          const numberValue = parseInt(value[0], 10);
          dispatch({
            type: 'profileSlice/setDivision',
            payload: divisions?.find((division) => division.id === numberValue),
          });
        }}
      />
    </div>
  );
};

export default Toolbar;
