/* eslint-disable react/no-array-index-key */
import './App.css';
import { Route, Routes, useLocation } from 'react-router-dom';
import { Suspense } from 'react';
import AppContainer from './components/AppContainer.component';
import Drawer from './components/Drawer.component';
import Toolbar from './components/Toolbar.component';
import PrivateRoute from './components/PrivateRoute.component';
import truthyFilter from './model/truthy.filter';
import BaseRoutes, { DrawerLink, RoutesInterface } from './baseRoutes';

const App = <T extends DrawerLink>({
  addedRoutes = {},
  drawerSortFunction,
}: {
  addedRoutes?: RoutesInterface<T>;
  drawerSortFunction?: (a: T, b: T) => number;
}) => {
  const location = useLocation();
  const background = location.state && location.state.background;

  const mergedRoutes = { ...BaseRoutes, ...addedRoutes };

  const drawerLinks = Object.entries(mergedRoutes)
    .filter(([, route]) => truthyFilter(route.drawerLink))
    .map(([path, route]) => ({ to: path, ...route.drawerLink }) as T & { to: string })
    .toSorted(drawerSortFunction ?? (() => 0));

  const mergedPublicRoutes = Object.entries(mergedRoutes).filter(([, route]) => !route.private);
  const mergedPrivateRoutes = Object.entries(mergedRoutes).filter(([, route]) => route.private);
  const mergedPublicModalRoutes = Object.entries(mergedRoutes).filter(
    ([, route]) => route.modal && !route.private,
  );
  const mergedPrivateModalRoutes = Object.entries(mergedRoutes).filter(
    ([, route]) => route.modal && route.private,
  );

  return (
    <Suspense>
      <AppContainer drawer={<Drawer links={drawerLinks} />} toolbar={<Toolbar />}>
        <Routes location={background || location}>
          {mergedPublicRoutes.map(([path, route], index) => (
            <Route path={path} element={route.element} key={index} />
          ))}
          <Route
            element={
              <PrivateRoute requiredPermission={mergedRoutes[location.pathname]?.permissions} />
            }
          >
            {mergedPrivateRoutes.map(([path, route], index) => (
              <Route path={path} element={route.element} key={index} />
            ))}
          </Route>
        </Routes>
        {background && (
          <Routes>
            {mergedPublicModalRoutes.map(([path, route], index) => (
              <Route path={path} element={route.element} key={index} />
            ))}
            <Route
              element={
                <PrivateRoute requiredPermission={mergedRoutes[location.pathname]?.permissions} />
              }
            >
              {mergedPrivateModalRoutes.map(([path, route], index) => (
                <Route path={path} element={route.element} key={index} />
              ))}
            </Route>
          </Routes>
        )}
      </AppContainer>
    </Suspense>
  );
};

export default App;
