import {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
  createApi,
  fetchBaseQuery,
} from '@reduxjs/toolkit/query/react';
import { logout } from '../helpers/logout.action';

const baseQuery = fetchBaseQuery({
  baseUrl: import.meta.env.VITE_API_BASE_URL as string,
  credentials: 'include',
  responseHandler: 'content-type',
});

// @ts-expect-error types aren't exposed from rtk query
let refreshPromise = null;

const baseQueryWithReauth: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (
  args,
  api,
  extraOptions,
) => {
  let result = await baseQuery(args, api, extraOptions);
  if (result.error && result.error.status === 401) {
    // @ts-expect-error types aren't exposed from rtk query
    if (!refreshPromise) {
      refreshPromise = baseQuery('/v1/refresh', api, extraOptions);
    }
    refreshPromise.then(
      () => {
        refreshPromise = null;
      },
      () => {
        refreshPromise = null;
      },
    );
    const refreshResult = await refreshPromise;

    if (refreshResult.meta?.response?.status === 200) {
      result = await baseQuery(args, api, extraOptions);
    } else {
      api.dispatch(logout());
    }
  }
  return result;
};

export const api = createApi({
  reducerPath: 'api',
  baseQuery: baseQueryWithReauth,
  // Tags for caching
  tagTypes: ['auth', 'division', 'profile', 'user', 'role', 'userRole', 'permission'],
  // Endpoints are defined in adjacent files, e.g. auth.api.ts
  endpoints: () => ({}),
});

export const enhancedApi = api.enhanceEndpoints({
  endpoints: () => ({
    getPost: () => 'test',
  }),
});
