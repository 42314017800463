import { useTranslation } from 'react-i18next';
import { QueryStatus } from '@reduxjs/toolkit/query';
import EntityTable from '../components/EntityTable.component';
import { useGetDivisionsQuery } from '../store/api/divisions.api';
import { useGetProfileQuery } from '../store/api/profile.api';
import { useGetMyPermissionsQuery } from '../store/api/permissions.api';
import Permissions from '../model/permissions.model';

const Divisions = () => {
  const { t } = useTranslation('divisions');
  const { data: divisions, status: divisionsStatus } = useGetDivisionsQuery({});
  const { data: profile, status: profileStatus } = useGetProfileQuery(null);
  const { data: permissions, status: permissionsStatus } = useGetMyPermissionsQuery(
    profile?.user?.division_id ?? -1,
  );

  if (
    divisionsStatus === QueryStatus.pending ||
    profileStatus === QueryStatus.pending ||
    permissionsStatus === QueryStatus.pending
  ) {
    return <div>{t('loading')}</div>;
  }

  const data = divisions?.map((division) => ({
    id: division.id,
    name: division.name,
    parentDivision: division.parent_division?.name ?? t('table.noParent'),
  }));

  return (
    <EntityTable
      title={t('title')}
      headers={[t('table.headers.name'), t('table.headers.parent')]}
      data={data}
      growingColumnIndex={0}
      permissions={{
        add: () => permissions?.some((permission) => permission === Permissions.CREATE_DIVISION),
        edit: () => permissions?.some((permission) => permission === Permissions.EDIT_DIVISION),
        delete: () => permissions?.some((permission) => permission === Permissions.DELETE_DIVISION),
      }}
    />
  );
};

export default Divisions;
