import { api } from '.';
import Division from '../../model/division.model';
import User from '../../model/user.model';

export const profileApi = api.injectEndpoints({
  endpoints: (build) => ({
    getProfile: build.query<{ user: User }, null>({
      query: () => ({
        url: `/v1/profile`,
        method: 'GET',
      }),
      providesTags: ['profile'],
    }),
    getProfileDivisions: build.query<Division[], null>({
      query: () => ({
        url: `/v1/profile/divisions`,
        method: 'GET',
      }),
      providesTags: ['profile'],
    }),
    editProfile: build.mutation<User, Pick<User, 'username'>>({
      query: (data) => ({
        url: `/v1/profile`,
        method: 'PATCH',
        data,
      }),
      invalidatesTags: ['profile'],
    }),
  }),
});

export const { useGetProfileQuery, useGetProfileDivisionsQuery, useEditProfileMutation } =
  profileApi;

export const {
  endpoints: { getProfile, getProfileDivisions, editProfile },
} = profileApi;
