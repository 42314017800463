import { api } from '.';

export const authApi = api.injectEndpoints({
  endpoints: (build) => ({
    login: build.mutation<unknown, { email: string; password: string }>({
      query: (credentials: { email: string; password: string }) => ({
        url: '/v1/login',
        method: 'POST',
        body: credentials,
      }),
    }),
    logout: build.mutation<unknown, void>({
      query: () => ({
        url: '/v1/logout',
        method: 'POST',
      }),
    }),
    register: build.mutation<unknown, { email: string; password: string }>({
      query: (credentials: { email: string; password: string }) => ({
        url: '/v1/register',
        method: 'POST',
        body: credentials,
      }),
    }),
    resetPassword: build.mutation<
      unknown,
      { password: string; repeatPassword: string; token: string }
    >({
      query: (credentials: { password: string; repeatPassword: string; token: string }) => ({
        url: `/v1/reset_password/${credentials.token}`,
        method: 'POST',
        body: credentials,
      }),
    }),
    forgotPassword: build.mutation<unknown, { email: string }>({
      query: (credentials: { email: string }) => ({
        url: '/v1/reset_password',
        method: 'POST',
        body: credentials,
      }),
    }),
  }),
});

export const {
  useLoginMutation,
  useLogoutMutation,
  useRegisterMutation,
  useResetPasswordMutation,
  useForgotPasswordMutation,
} = authApi;

export const {
  endpoints: { login, register, resetPassword, forgotPassword },
} = authApi;
