const TextButton = ({
  onClick,
  children,
  type,
  disabled,
}: {
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  children?: React.ReactNode;
  type?: 'submit' | 'button';
  disabled?: boolean;
}) => {
  const buttonClass =
    'bg-slate-500 hover:border-slate-900 text-white focus:outline-none p-2 rounded-md';
  if (type === 'submit') {
    return (
      <button disabled={disabled} type="submit" className={buttonClass} onClick={onClick}>
        {children}
      </button>
    );
  }

  return (
    <button disabled={disabled} type="button" className={buttonClass} onClick={onClick}>
      {children}
    </button>
  );
};

export default TextButton;
