import Login from './page/Login.page';
import Register from './page/Register.page';
import Users from './page/Users.page';
import Divisions from './page/Divisions.page';
import AddDivisionModal from './components/AddDivisionModal.component';
import EditDivisionModal from './components/EditDivisionModal.component';
import DeleteDivisionModal from './components/DeleteDivisionModal.component';
import ProfileModal from './components/ProfileModal.component';
import AddUserModal from './components/AddUserModal.component';
import EditUserModal from './components/EditUserModal.component';
import DeleteUserModal from './components/DeleteUserModal.component';
import ResetPassword from './page/ResetPassword.page';
import ForgotPassword from './page/ForgotPassword.page';
import EditUserRolesModal from './components/EditUserRolesModal.component';
import Permissions from './model/permissions.model';
import Unauthorized from './page/Unauthorized.page';
import fork from './assets/git-fork-thin-svgrepo-com.svg';
import users from './assets/users-three-thin-svgrepo-com.svg';
import User from './page/User.page';
import Division from './page/Division.page';

export interface DrawerLink {
  labelKey: string;
  iconSource: string;
}

export interface RoutesInterface<T extends DrawerLink> {
  [path: string]: {
    element: JSX.Element;
    permissions?: Permissions;
    private?: boolean;
    modal?: boolean;
    drawerLink?: T;
  };
}

const BaseRoutes: RoutesInterface<DrawerLink> = {
  '*': {
    element: <Divisions />,
  },
  '/login': {
    element: <Login />,
  },
  '/register': {
    element: <Register />,
  },
  '/forgot': {
    element: <ForgotPassword />,
  },
  '/reset/:token': {
    element: <ResetPassword />,
  },
  '/unauthorized': {
    element: <Unauthorized />,
  },
  '/divisions': {
    element: <Divisions />,
    permissions: Permissions.READ_DIVISION,
    private: true,
    drawerLink: { labelKey: 'drawer.divisions', iconSource: fork },
  },
  '/divisions/:id': {
    element: <Division />,
    permissions: Permissions.READ_DIVISION,
    private: true,
  },
  '/divisions/add': {
    element: <AddDivisionModal />,
    permissions: Permissions.CREATE_DIVISION,
    private: true,
    modal: true,
  },
  '/divisions/edit/:id': {
    element: <EditDivisionModal />,
    permissions: Permissions.EDIT_DIVISION,
    private: true,
    modal: true,
  },
  '/divisions/delete/:id': {
    element: <DeleteDivisionModal />,
    permissions: Permissions.DELETE_DIVISION,
    private: true,
    modal: true,
  },
  '/users': {
    element: <Users />,
    permissions: Permissions.READ_USER,
    private: true,
    drawerLink: { labelKey: 'drawer.users', iconSource: users },
  },
  '/users/:id': {
    element: <User />,
    permissions: Permissions.READ_USER,
    private: true,
  },
  '/users/add': {
    element: <AddUserModal />,
    permissions: Permissions.CREATE_USER,
    private: true,
    modal: true,
  },
  '/users/edit/:id': {
    element: <EditUserModal />,
    permissions: Permissions.EDIT_USER,
    private: true,
    modal: true,
  },
  '/users/delete/:id': {
    element: <DeleteUserModal />,
    permissions: Permissions.DELETE_USER,
    private: true,
    modal: true,
  },
  '/profile': {
    element: <ProfileModal />,
    private: true,
    modal: true,
  },
  '/users/roles/:id': {
    element: <EditUserRolesModal />,
    permissions: Permissions.EDIT_USER,
    private: true,
    modal: true,
  },
};

export default BaseRoutes;
