import { api } from '.';
import Division from '../../model/division.model';

export const divisionsApi = api.injectEndpoints({
  endpoints: (build) => ({
    getDivisions: build.query<Division[], unknown>({
      query: () => ({
        url: '/v1/divisions',
        method: 'GET',
      }),
      providesTags: ['division'],
    }),
    addDivision: build.mutation<Division, { name: string; parentDivisionId: number }>({
      query: (body) => ({
        url: '/v1/divisions',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['division'],
    }),
    editDivision: build.mutation<
      Division,
      { division: Partial<Division> & { parentDivisionId: number }; id: number }
    >({
      query: ({ id, division }) => ({
        url: `/v1/divisions/${id}`,
        method: 'PATCH',
        body: division,
      }),
      invalidatesTags: ['division'],
    }),
    deleteDivision: build.mutation<unknown, number>({
      query: (id) => ({
        url: `/v1/divisions/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['division'],
    }),
    getDivision: build.query<Division, number>({
      query: (id) => ({
        url: `/v1/divisions/${id}`,
        method: 'GET',
      }),
      providesTags: ['division'],
    }),
  }),
});

export const {
  useGetDivisionsQuery,
  useAddDivisionMutation,
  useEditDivisionMutation,
  useDeleteDivisionMutation,
  useGetDivisionQuery,
} = divisionsApi;

export const {
  endpoints: { getDivisions, addDivision, editDivision, deleteDivision },
} = divisionsApi;
