enum Permissions {
  READ_USER = 'read_user',
  CREATE_USER = 'create_user',
  EDIT_USER = 'edit_user',
  DELETE_USER = 'delete_user',
  READ_DIVISION = 'read_division',
  CREATE_DIVISION = 'create_division',
  EDIT_DIVISION = 'edit_division',
  DELETE_DIVISION = 'delete_division',
  READ_USER_ROLE = 'read_user_role',
  EDIT_USER_ROLE = 'edit_user_role',
  EDIT_ROLE = 'edit_role',
}

export default Permissions;
