import { createSlice } from '@reduxjs/toolkit';
import UsersState from '../model/state/usersState.model';
import { usersApi } from './api/user.api';
import { LOGOUT_ACTION_TYPE } from './helpers/logout.action';

const initialState: UsersState = {
  status: 'idle',
  users: null,
};

const slice = createSlice({
  name: 'userSlice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(LOGOUT_ACTION_TYPE, () => initialState)
      .addMatcher(usersApi.endpoints.getUsers.matchPending, (state) => {
        state.status = 'loading';
      })
      .addMatcher(usersApi.endpoints.getUsers.matchFulfilled, (state, action) => {
        state.users = action.payload.results;
        state.status = 'succeeded';
      })
      .addMatcher(usersApi.endpoints.getUsers.matchRejected, (state) => {
        state.status = 'failed';
      });
  },
});

export default slice.reducer;
