/* eslint-disable react/jsx-props-no-spreading */
import { useTranslation } from 'react-i18next';
import { components, ClearIndicatorProps } from 'react-select';
import close from '../../assets/close-svgrepo-com.svg';

const ClearIndicator = (props: ClearIndicatorProps<{ label: string; value: string }>) => {
  const { t } = useTranslation('common');
  const { className } = props;
  return (
    <components.ClearIndicator {...props} className={`${className} z-20`}>
      <img
        src={close}
        alt={t('iconsAlt.removeAll')}
        height={24}
        width={24}
        className="hover:cursor-pointer hover:bg-slate-300 hover:bg-opacity-50 rounded-full p-1"
      />
    </components.ClearIndicator>
  );
};

export default ClearIndicator;
