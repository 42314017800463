import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import TextInput from './TextInput.component';
import Modal from './Modal.component';
import TextButton from './TextButton.component';
import { useAddUserMutation } from '../store/api/user.api';
import { useAppSelector } from '../store';

const AddUserModal = () => {
  const { t } = useTranslation('home');
  const context = 'add';

  const [modalActive, setModalActive] = useState(true);

  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [password, setPassword] = useState('');

  const { selectedDivision } = useAppSelector((state) => state.profileSlice);
  const [addUser] = useAddUserMutation();

  const shouldClose = !selectedDivision;

  useEffect(() => {
    if (shouldClose) {
      setModalActive(false);
    }
  });

  return (
    <Modal isActive={modalActive} header={t('modal.header', { context })}>
      {shouldClose ? null : (
        <form
          className="flex flex-col justify-between flex-1"
          onSubmit={(event) => {
            event.preventDefault();
            addUser({ username, email, password, divisionId: selectedDivision.id })
              .unwrap()
              .then(() => {
                setModalActive(false);
              })
              .catch((apiError) => {
                setError(t([apiError.code, 'modal.error'], { context }));
              });
          }}
        >
          <div className="flex flex-col gap-2">
            <TextInput label={t('modal.name', { context })} onChange={setUsername} required />
            <TextInput label={t('modal.email', { context })} onChange={setEmail} required />
            <TextInput label={t('modal.password', { context })} onChange={setPassword} required />
          </div>
          {error && <div className="text-rose-500">{error}</div>}
          <TextButton type="submit">{t('modal.submit', { context })}</TextButton>
        </form>
      )}
    </Modal>
  );
};

export default AddUserModal;
