import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import TextInput from './TextInput.component';
import Modal from './Modal.component';
import TextButton from './TextButton.component';
import { useAddDivisionMutation } from '../store/api/divisions.api';
import { useAppSelector } from '../store';
import SelectInput from './SelectInput.component';
import Division from '../model/division.model';

const AddDivisionModal = () => {
  const [modalActive, setModalActive] = useState(true);
  const { t } = useTranslation('divisions');
  const [addDivision] = useAddDivisionMutation();
  const { selectedDivision } = useAppSelector((state) => state.profileSlice);
  const { divisions } = useAppSelector((state) => state.divisionsSlice);
  const [name, setName] = useState('');
  const [error, setError] = useState('');
  const [parentDivision, setParentDivision] = useState<Division | null>(selectedDivision);
  const context = 'add';
  const shouldClose = !selectedDivision || !divisions || divisions.length === 0 || !parentDivision;
  useEffect(() => {
    if (shouldClose) {
      setModalActive(false);
    }
  }, [shouldClose]);

  if (shouldClose) {
    return null;
  }

  return (
    <Modal header={t('modal.header', { context })} isActive={modalActive}>
      <form
        className="flex flex-col justify-between flex-1"
        onSubmit={(event) => {
          event.preventDefault();
          if (!parentDivision) {
            setError(t('modal.parentRequired', { context }));
            return;
          }
          addDivision({ name, parentDivisionId: parentDivision.id })
            .unwrap()
            .then(() => {
              setModalActive(false);
            })
            .catch((apiError) => {
              setError(t([apiError.code, 'modal.error'], { context }));
            });
        }}
      >
        <div className="flex flex-col gap-2">
          <TextInput label={t('modal.name', { context })} onChange={setName} required />
          <SelectInput
            label={t('modal.parent', { context })}
            options={divisions?.map((d) => ({ label: d.name, value: d.id.toString() })) ?? []}
            value={{ value: parentDivision.id.toString(), label: parentDivision.name }}
            onChange={(value) => {
              const id = parseInt(value[0], 10);
              setParentDivision(divisions.find((d) => d.id === id)!);
            }}
            required
          />
        </div>
        {error && <div className="text-rose-500">{error}</div>}
        <TextButton type="submit">{t('modal.submit', { context })}</TextButton>
      </form>
    </Modal>
  );
};

export default AddDivisionModal;
