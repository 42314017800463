import { useAppSelector } from '../store';
import { useGetDivisionsQuery } from '../store/api/divisions.api';
import { useGetProfileQuery } from '../store/api/profile.api';
import { useGetRolesQuery } from '../store/api/roles.api';
import { useGetUsersQuery } from '../store/api/user.api';

const AppContainer = ({
  children,
  drawer,
  toolbar,
}: {
  children: React.ReactNode;
  drawer?: React.ReactNode;
  toolbar?: React.ReactNode;
}) => {
  const { user } = useAppSelector((state) => state.profileSlice);
  const { selectedDivision } = useAppSelector((state) => state.profileSlice);

  useGetUsersQuery({
    divisionIds: selectedDivision?.id ? [selectedDivision.id] : undefined,
  });
  useGetDivisionsQuery(selectedDivision?.id ?? undefined);
  useGetRolesQuery(null);
  useGetProfileQuery(null);
  if (!user) {
    return children;
  }
  return (
    <div className="flex flex-1 flex-row h-full">
      {drawer}
      <div className="flex flex-col w-full">
        {toolbar}
        <div className="p-4 flex items-start flex-1">{children}</div>
      </div>
    </div>
  );
};

export default AppContainer;
