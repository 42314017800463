import { api } from '.';
import Role from '../../model/role.model';
import UserRole from '../../model/userRole.model';

export const userRolesApi = api.injectEndpoints({
  endpoints: (build) => ({
    getUserRoles: build.query<UserRole[], number>({
      query: (id) => ({
        url: `/v1/users/${id}/roles`,
        method: 'GET',
      }),
      providesTags: ['userRole'],
    }),
    setUserRoles: build.mutation<
      unknown,
      { id: number; roles: Role[]; divisionId: number; targetUserId: number }
    >({
      query: ({ id, roles, divisionId, targetUserId }) => ({
        url: `/v1/users/${id}/roles`,
        method: 'PUT',
        body: { roles, divisionId, targetUserId },
      }),
      invalidatesTags: ['userRole', 'division', 'user'],
    }),
    getDivisionUserRoles: build.query<{ [key: number]: UserRole[] }, number>({
      query: (id) => ({
        url: `/v1/divisions/${id}/roles`,
        method: 'GET',
      }),
      providesTags: ['userRole'],
    }),
  }),
});

export const { useGetUserRolesQuery, useSetUserRolesMutation, useGetDivisionUserRolesQuery } =
  userRolesApi;

export const {
  endpoints: { getUserRoles },
} = userRolesApi;
