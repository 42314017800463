import { createSlice } from '@reduxjs/toolkit';
import RolesState from '../model/state/rolesState.model';
import { rolesApi } from './api/roles.api';
import { LOGOUT_ACTION_TYPE } from './helpers/logout.action';

const initialState: RolesState = {
  status: 'idle',
  roles: undefined,
};

const slice = createSlice({
  name: 'rolesSlice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(LOGOUT_ACTION_TYPE, () => initialState)
      .addMatcher(rolesApi.endpoints.getRoles.matchPending, (state) => {
        state.status = 'loading';
      })
      .addMatcher(rolesApi.endpoints.getRoles.matchFulfilled, (state, action) => {
        state.roles = action.payload;
        state.status = 'succeeded';
      })
      .addMatcher(rolesApi.endpoints.getRoles.matchRejected, (state) => {
        state.status = 'failed';
      });
  },
});

export default slice.reducer;
