import { createSlice } from '@reduxjs/toolkit';
import { authApi } from './api/auth.api';
import AuthState from '../model/state/authState.model';
import { LOGOUT_ACTION_TYPE } from './helpers/logout.action';
import i18n from '../i18n';
import { ResetValidationErrors, ResetZodErrors } from '../model/errors/auth.error';

const initialState: AuthState = {
  status: 'idle',
  message: '',
};

const slice = createSlice({
  name: 'authSlice',
  initialState,
  reducers: {
    setIdle: (state) => {
      if (state.status === 'succeeded') {
        state.status = 'idle';
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(LOGOUT_ACTION_TYPE, () => {
        return initialState;
      })
      .addMatcher(authApi.endpoints.login.matchPending, (state) => {
        state.status = 'loading';
      })
      .addMatcher(authApi.endpoints.login.matchFulfilled, (state) => {
        state.status = 'succeeded';
      })
      .addMatcher(authApi.endpoints.login.matchRejected, (state, action) => {
        state.status = 'failed';
        state.message =
          action?.payload?.status === 401 || action?.payload?.status === 404
            ? 'invalid'
            : 'somethingWentWrong';
      })
      .addMatcher(authApi.endpoints.forgotPassword.matchPending, (state) => {
        state.status = 'loading';
      })
      .addMatcher(authApi.endpoints.forgotPassword.matchFulfilled, (state) => {
        state.status = 'succeeded';
      })
      .addMatcher(authApi.endpoints.forgotPassword.matchRejected, (state, action) => {
        state.status = 'failed';
        state.message =
          action?.payload?.status === 404 || action?.payload?.status === 400
            ? 'invalid'
            : 'somethingWentWrong';
      })
      .addMatcher(authApi.endpoints.resetPassword.matchPending, (state) => {
        state.status = 'loading';
      })
      .addMatcher(authApi.endpoints.resetPassword.matchFulfilled, (state) => {
        state.status = 'succeeded';
      })
      .addMatcher(authApi.endpoints.resetPassword.matchRejected, (state, action) => {
        state.status = 'failed';
        state.message = 'somethingWentWrong';
        if (Array.isArray(action?.payload?.data)) {
          const firstItem = action?.payload?.data?.[0] as {
            code: ResetZodErrors;
            [key: string]: unknown;
          };
          if (!firstItem) {
            return;
          }
          const { code, ...options } = firstItem;
          if (!code) {
            return;
          }
          state.message = i18n.t(code, options);
        } else {
          const { message } = action?.payload?.data as {
            message: ResetValidationErrors;
          };
          if (!message) {
            return;
          }
          state.message = i18n.t(message);
        }
      });
  },
});

export const { setIdle } = slice.actions;

export default slice.reducer;
