import { useTranslation } from 'react-i18next';
import warning from '../assets/warning-octagon-thin-svgrepo-com.svg';

const Unauthorized = () => {
  const { t } = useTranslation('common');
  return (
    <div className="flex flex-1 h-full items-start relative">
      <div className="card flex flex-col gap-4 mt-[10%]">
        <div className="flex flex-row gap-2 justify-center flex-1">
          <div className="text-xl text-black items-center flex">{t('unauthorized.title')}</div>
          <img src={warning} alt={t('iconsAlt.warning')} width={36} />
        </div>
        <div className="flex-1 text-black">{t('unauthorized.description')}</div>
      </div>
    </div>
  );
};

export default Unauthorized;
