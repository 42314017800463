import { api } from '.';
import Page from '../../modal/page.model';
import User from '../../model/user.model';

export const usersApi = api.injectEndpoints({
  endpoints: (build) => ({
    getUsers: build.query<Page<User>, { divisionIds?: number[] }>({
      query: () => ({
        url: `/v1/users`,
        method: 'GET',
      }),
      providesTags: ['user'],
    }),
    addUser: build.mutation<User, Partial<User> & { password: string; divisionId: number }>({
      query: (body) => ({
        url: `/v1/users`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['user', 'division'],
    }),
    editUser: build.mutation<User, { id: number; user: Partial<User> }>({
      query: ({ id, user }) => ({
        url: `/v1/users/${id}`,
        method: 'PATCH',
        mode: 'cors',
        body: user,
      }),
      invalidatesTags: ['user', 'division'],
      onQueryStarted({ user }, { dispatch, queryFulfilled }) {
        const result = dispatch(
          usersApi.util.updateQueryData(
            'getUsers',
            { divisionIds: [user.division_id ?? -1] },
            (draft) => {
              Object.assign(draft, user);
            },
          ),
        );
        queryFulfilled.catch(result.undo);
      },
    }),
    deleteUser: build.mutation<unknown, number>({
      query: (id) => ({
        url: `/v1/users/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['user', 'division'],
    }),
    getUser: build.query<User, number>({
      query: (id) => ({
        url: `/v1/users/${id}`,
        method: 'GET',
      }),
    }),
  }),
});

export const {
  useGetUsersQuery,
  useAddUserMutation,
  useEditUserMutation,
  useDeleteUserMutation,
  useGetUserQuery,
} = usersApi;

export const {
  endpoints: { getUsers, addUser, editUser, deleteUser, getUser },
} = usersApi;
