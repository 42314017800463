import { configureStore } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { api } from './api';
import authSlice from './authSlice';
import divisionsSlice from './divisionsSlice';
import profileSlice from './profile.slice';
import usersSlice from './usersSlice';
import rolesSlice from './roles.slice';

export const createStore = () =>
  configureStore({
    reducer: {
      [api.reducerPath]: api.reducer,
      authSlice,
      divisionsSlice,
      profileSlice,
      usersSlice,
      rolesSlice,
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(api.middleware),
  });

export const store = createStore();

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
