import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import TextInput from './TextInput.component';
import Modal from './Modal.component';
import SelectInput from './SelectInput.component';
import TextButton from './TextButton.component';
import { useAppSelector } from '../store';
import { useEditUserMutation } from '../store/api/user.api';

const EditUserModal = () => {
  const { t } = useTranslation('home');
  const params = useParams();
  const context = 'edit';

  const { users, status: usersStatus } = useAppSelector((state) => state.usersSlice);
  const { divisions, status: divisionStatus } = useAppSelector((state) => state.divisionsSlice);
  const user = users?.find((u) => u.id === parseInt(params.id ?? '-1', 10));
  const [editUser] = useEditUserMutation();

  const [modalActive, setModalActive] = useState(true);
  const [username, setUsername] = useState(user?.username);
  const [email, setEmail] = useState(user?.email);
  const [error, setError] = useState('');

  const shouldClose =
    !params?.id ||
    Number.isNaN(parseInt(params.id, 10)) ||
    (!user && usersStatus === 'failed') ||
    (!divisions && divisionStatus === 'failed');

  useEffect(() => {
    if (shouldClose) {
      setModalActive(false);
    }
  }, [shouldClose]);

  if (shouldClose || !user || !divisions) {
    return null;
  }

  return (
    <Modal isActive={modalActive} header={t('modal.header', { context })}>
      {shouldClose || !user || !divisions ? null : (
        <form
          className="flex flex-col justify-between flex-1"
          onSubmit={(event) => {
            event.preventDefault();
            // assignment on a separate row to avoid ts-expect-error affecting more than just the id
            // @ts-expect-error params is defined here, as per the assertion
            const id = parseInt(params.id, 10);
            editUser({ id, user: { username, email } })
              .unwrap()
              .then(() => {
                setModalActive(false);
              })
              .catch((apiError) => {
                setError(t([apiError.code, 'modal.error'], { context }));
              });
          }}
        >
          <div className="flex flex-col gap-2">
            <TextInput
              label={t('modal.name', { context })}
              onChange={setUsername}
              value={username}
            />
            <TextInput label={t('modal.email', { context })} onChange={setEmail} value={email} />
            <SelectInput
              label={t('modal.division', { context })}
              options={
                divisions?.map((division) => ({
                  label: division.name,
                  value: division.id.toString(),
                })) ?? []
              }
              value={
                user.division
                  ? [
                      {
                        label: user.division.name,
                        value: user.division.id.toString(),
                      },
                    ]
                  : undefined
              }
            />
            {error && <div className="text-rose-500">{error}</div>}
          </div>
          <TextButton type="submit">{t('modal.submit', { context })}</TextButton>
        </form>
      )}
    </Modal>
  );
};

export default EditUserModal;
