import { useTranslation } from 'react-i18next';
import { useEffect, useRef, useState } from 'react';
import { Link, Navigate } from 'react-router-dom';
import toast, { Toaster } from 'react-hot-toast';
import TextInput from '../components/TextInput.component';
import { useForgotPasswordMutation } from '../store/api/auth.api';
import TextButton from '../components/TextButton.component';
import { useAppSelector } from '../store';

const ForgotPassword = () => {
  const { t } = useTranslation('auth');
  const [email, setEmail] = useState('');
  const [forgotPassword, { reset }] = useForgotPasswordMutation();
  const resetRef = useRef(reset);
  resetRef.current = reset;
  const { status, message } = useAppSelector((state) => state.authSlice);
  const { user } = useAppSelector((state) => state.profileSlice);
  const context = 'forgot';

  if (user) {
    <Navigate to="/" />;
  }

  useEffect(() => {
    if (status === 'succeeded') {
      toast.success(t('success', { context }), {
        duration: 4000,
      });
      resetRef.current();
    }
    return resetRef.current;
  }, [status]);

  return (
    <div className="flex-1 flex inset-0 justify-center items-center h-full">
      <Toaster
        toastOptions={{
          className: 'bg-slate-50 text-black',
        }}
      />
      <form
        className="card text-black flex flex-col gap-y-4"
        onSubmit={(event) => {
          event.preventDefault();
          forgotPassword({ email });
        }}
      >
        <h1 className="text-3xl md:mx-10 text-center">{t('title', { context })}</h1>
        <TextInput label={t('email')} value={email} onChange={setEmail} type="email" required />
        {status === 'failed' && (
          <div className="text-red-500 text-center">
            {t(message ?? 'somethingWentWrong', { context })}
          </div>
        )}
        <TextButton disabled={status === 'loading'} type="submit">
          {status === 'loading' ? t('loading') : t('submitButton', { context })}
        </TextButton>
        <Link to="/login" className="font-medium text-blue-600 hover:underline text-center">
          {t('linkLogin', { context })}
        </Link>
      </form>
    </div>
  );
};

export default ForgotPassword;
