import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { QueryStatus } from '@reduxjs/toolkit/query';
import Modal from './Modal.component';
import SelectInput from './SelectInput.component';
import TextButton from './TextButton.component';
import { useGetUsersQuery } from '../store/api/user.api';
import { useGetRolesQuery } from '../store/api/roles.api';
import Role from '../model/role.model';
import { useGetProfileQuery } from '../store/api/profile.api';
import { useGetUserRolesQuery, useSetUserRolesMutation } from '../store/api/userRoles.api';

const EditUserRolesModal = () => {
  const { t } = useTranslation(['home', 'roles']);
  const context = 'roles';
  const params = useParams();
  const userId = parseInt(params.id ?? '-1', 10);

  const [newRoles, setNewRoles] = useState<Role[]>([]);
  const [modalActive, setModalActive] = useState(true);
  const [error, setError] = useState('');

  const { data: roles, status: rolesStatus } = useGetRolesQuery(null);
  const { data: profile, status: profileStatus } = useGetProfileQuery(null);
  const { data: userPage, status: usersStatus } = useGetUsersQuery({});
  const { data: userRoles, status: userRolesStatus } = useGetUserRolesQuery(userId);

  const [setUserRoles] = useSetUserRolesMutation();

  const user = userPage?.results.find((u) => u.id === userId);

  const shouldClose =
    userId === -1 ||
    usersStatus === QueryStatus.rejected ||
    rolesStatus === QueryStatus.rejected ||
    profileStatus === QueryStatus.rejected ||
    userRolesStatus === QueryStatus.rejected;

  useEffect(() => {
    if (shouldClose) {
      setModalActive(false);
    }
  }, [shouldClose]);

  useEffect(() => {
    if (userRoles) {
      setNewRoles(userRoles.map(({ role }) => role));
    }
  }, [userRoles]);

  return (
    <Modal
      isActive={modalActive}
      header={`${t('modal.header', { context, name: user?.username })}`}
    >
      {shouldClose || !user?.division || !roles || !profile || !userRoles ? null : (
        <form
          className="flex flex-col justify-between flex-1"
          onSubmit={(event) => {
            event.preventDefault();
            // @ts-expect-error params is defined here, as per the assertion
            const id = parseInt(params.id, 10);
            // @ts-expect-error params is defined here, as per the assertion
            const divisionId = user.division.id;
            setUserRoles({
              id: profile?.user.id ?? 0,
              roles: newRoles,
              divisionId,
              targetUserId: id,
            })
              .unwrap()
              .then(() => {
                setModalActive(false);
              })
              .catch(() => {
                setError(t('modal.error', { context }));
              });
          }}
        >
          <div className="flex flex-col gap-2">
            <SelectInput
              multiple
              onChange={(value) => {
                setNewRoles(roles.filter((role) => value.includes(role.id.toString())));
              }}
              options={roles.map((role) => ({
                label: t(`roles:${role.name}`),
                value: role.id.toString(),
              }))}
              value={userRoles.map(({ role }) => ({
                value: role.id.toString(),
                label: t(`roles:${role.name}`),
              }))}
              label={t('modal.label', { context })}
            />
            {error && <div className="text-rose-500">{error}</div>}
          </div>
          <TextButton type="submit">{t('modal.submit', { context })}</TextButton>
        </form>
      )}
    </Modal>
  );
};

export default EditUserRolesModal;
