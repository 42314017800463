const IconButton = ({
  onClick,
  src,
  alt,
  className,
}: {
  onClick: () => void;
  src: string;
  alt: string;
  className?: string;
}) => {
  return (
    <button
      className={`bg-black bg-opacity-0 border-none hover:bg-opacity-5 rounded-full aspect-square p-2 z-10 ${className ?? ''}`}
      onClick={onClick}
      type="button"
      name="close-button"
    >
      <img src={src} width={24} height={24} alt={alt} />
    </button>
  );
};

export default IconButton;
