import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import TextInput from './TextInput.component';
import Modal from './Modal.component';
import SelectInput from './SelectInput.component';
import TextButton from './TextButton.component';
import { useAppSelector } from '../store';
import { useEditDivisionMutation } from '../store/api/divisions.api';

const EditDivisionModal = () => {
  const { t } = useTranslation('divisions');
  const [modalActive, setModalActive] = useState(true);
  const [editDivision] = useEditDivisionMutation();
  const params = useParams();
  const { divisions } = useAppSelector((state) => state.divisionsSlice);
  const division = divisions?.find((d) => d.id === parseInt(params.id ?? '-1', 10));
  const [name, setName] = useState(division?.name ?? '');
  const [parentDivisionId, setParentDivisionId] = useState<number | undefined>(
    division?.parent_division?.id,
  );
  const [error, setError] = useState('');
  const context = 'edit';
  const shouldClose = !params.id || Number.isNaN(parseInt(params.id, 10)) || !division;
  useEffect(() => {
    if (shouldClose) {
      setModalActive(false);
    }
  }, [shouldClose]);

  if (shouldClose) {
    return null;
  }

  return (
    <Modal isActive={modalActive} header={t('modal.header', { context })}>
      <form
        className="flex flex-col justify-between flex-1"
        onSubmit={(event) => {
          event.preventDefault();
          if (!name || !parentDivisionId) {
            return;
          }
          // assignment on a separate row to avoid ts-expect-error affecting more than just the id
          // @ts-expect-error params is defined here, as per the assertion
          const id = parseInt(params.id, 10);
          editDivision({ id, division: { name, parentDivisionId } })
            .unwrap()
            .then(() => {
              setModalActive(false);
            })
            .catch((apiError) => {
              setError(t([apiError.code, 'modal.error'], { context }));
            });
        }}
      >
        <div className="flex-1 flex flex-col gap-2">
          <TextInput label={t('modal.name', { context })} onChange={setName} value={name} />
          <SelectInput
            label={t('modal.parent', { context })}
            options={divisions?.map((d) => ({ label: d.name, value: d.id.toString() })) ?? []}
            value={
              division.parent_division
                ? [
                    {
                      label: division.parent_division.name,
                      value: division.parent_division.id.toString(),
                    },
                  ]
                : undefined
            }
            onChange={(value) => {
              if (value && value.length > 0) {
                setParentDivisionId(parseInt(value[0], 10));
              }
            }}
          />
          {error && <div className="text-rose-500">{error}</div>}
        </div>
        <TextButton type="submit">{t('modal.submit', { context })}</TextButton>
      </form>
    </Modal>
  );
};

export default EditDivisionModal;
