import Select from 'react-select';
import { useId, useState } from 'react';
import MultiValueRemove from './MultiValueRemove.component';
import ClearIndicator from './ClearIndicator.component';

const SelectInput = ({
  hidden,
  value,
  options,
  multiple,
  onChange,
  required,
  label,
}: {
  hidden?: boolean;
  value?: { value: string; label: string }[] | { value: string; label: string };
  options: { value: string; label: string }[];
  multiple?: boolean;
  onChange?: (value: string[]) => void;
  required?: boolean;
  label?: string;
}) => {
  const id = useId();
  const [isFocused, setIsFocused] = useState(false);
  const [anySelected, setAnySelected] = useState(
    !!(Array.isArray(value) ? value.length > 0 : value && value.value),
  );

  return (
    <div hidden={hidden} className="flex flex-col items-stretch relative min-w-32 text-sm">
      <label
        htmlFor={id}
        className={`z-10 absolute inset-0 h-1/2 flex justify-start items-center transition-all ${isFocused || anySelected ? '-translate-y-full' : ''}`}
      >
        <div
          className={`z-10 inset-0 p-2 absolute flex items-center transition-all translate-y-1/2 ${isFocused || anySelected ? 'text-2xs' : 'leading-none'} ease-out`}
        >
          {label}
        </div>
      </label>
      <Select
        inputId={id}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
        unstyled
        isMulti={multiple}
        className="flex-1 flex items-center z-auto"
        classNames={{
          container: () => `${isFocused ? 'shadow-sm shadow-slate-800' : ''}`,
          control: () =>
            `p-1.5 text-black bg-slate-200 border-collapse ${isFocused ? 'rounded-t-sm' : 'rounded-sm'} flex-1 flex flex-row flex-nowrap hover:cursor-pointer ease-out !min-h-0`,
          option: ({ data }) =>
            `text-black p-1.5 hover:cursor-pointer hover:bg-slate-300 hover:bg-opacity-50 ${options[options.length - 1].label === data.label ? 'rounded-b-sm' : ''}`,
          menu: () => 'bg-slate-200 rounded-b-sm absolute z-10 shadow-sm shadow-slate-800',
          input: () => 'border-none',
          valueContainer: () => 'block flex gap-1',
          multiValue: () => 'bg-slate-300 rounded-sm px-1 flex gap-1',
          indicatorSeparator: () => 'w-0 bg-slate-800 m-1',
        }}
        components={{
          MultiValueRemove,
          ClearIndicator,
        }}
        options={options}
        value={multiple ? undefined : value}
        defaultValue={multiple ? value : undefined}
        onChange={(newValue) => {
          if (Array.isArray(newValue)) {
            if (onChange) {
              onChange(newValue.map((v) => v.value));
              setAnySelected(newValue.length > 0);
            }
          } else if (newValue && 'value' in newValue) {
            if (onChange) {
              onChange([newValue.value]);
              setAnySelected(true);
            }
          }
        }}
        required={required}
        isSearchable={false}
        placeholder=""
        blurInputOnSelect
      />
    </div>
  );
};

export default SelectInput;
