import { createSlice } from '@reduxjs/toolkit';
import ProfileState from '../model/state/profileState.model';
import { profileApi } from './api/profile.api';
import { LOGOUT_ACTION_TYPE } from './helpers/logout.action';

const initialState: ProfileState = {
  status: 'idle',
  user: null,
  selectedDivision: null,
};

const slice = createSlice({
  name: 'profileSlice',
  initialState,
  reducers: {
    setDivision: (state, action) => {
      state.selectedDivision = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(LOGOUT_ACTION_TYPE, () => initialState)
      .addMatcher(profileApi.endpoints.getProfile.matchPending, (state) => {
        state.status = 'loading';
      })
      .addMatcher(profileApi.endpoints.getProfile.matchFulfilled, (state, action) => {
        const { user } = action.payload;
        state.user = user;
        state.status = 'succeeded';
      })
      .addMatcher(profileApi.endpoints.getProfile.matchRejected, (state) => {
        state.status = 'failed';
      })
      .addMatcher(profileApi.endpoints.getProfile.matchPending, (state) => {
        state.status = 'loading';
      })
      .addMatcher(profileApi.endpoints.getProfileDivisions.matchFulfilled, (state, action) => {
        const [selectedDivision] = action.payload;
        state.selectedDivision = selectedDivision;
        state.status = 'succeeded';
      })
      .addMatcher(profileApi.endpoints.getProfileDivisions.matchRejected, (state) => {
        state.status = 'failed';
      });
  },
});

export default slice.reducer;
