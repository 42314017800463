import { useTranslation } from 'react-i18next';
import { useEffect, useRef, useState } from 'react';
import { Link, Navigate, useParams } from 'react-router-dom';
import toast, { Toaster } from 'react-hot-toast';
import TextInput from '../components/TextInput.component';
import { useResetPasswordMutation } from '../store/api/auth.api';
import TextButton from '../components/TextButton.component';
import { useAppSelector } from '../store';

const ResetPassword = () => {
  const [newPassword, setNewPassword] = useState('');
  const [repeatPassword, setRepeatPassword] = useState('');
  const [error, setError] = useState('');

  const { t } = useTranslation('auth');
  const { token } = useParams();
  const { status, message } = useAppSelector((state) => state.authSlice);
  const { user } = useAppSelector((state) => state.profileSlice);

  const [resetPassword, { reset }] = useResetPasswordMutation();
  const resetRef = useRef(reset);
  resetRef.current = reset;

  const context = 'reset';

  useEffect(() => {
    if (status === 'succeeded') {
      toast.success(t('success', { context }), {
        duration: 4000,
      });
    }
  }, [status]);

  if (user) {
    return <Navigate to="/" />;
  }

  return (
    <div className="text-black flex-1 flex inset-0 justify-center items-center h-full">
      <Toaster />
      <form
        className="auth-form"
        onSubmit={(event) => {
          event.preventDefault();
          if (newPassword !== repeatPassword) {
            setError(t('passwordsDoNotMatch'));
            return;
          }
          setError('');
          resetPassword({ password: newPassword, repeatPassword, token: token ?? '' });
        }}
      >
        {token && status !== 'succeeded' ? (
          <>
            <h1 className="text-3xl text-center md:mx-10">{t('title', { context })}</h1>
            <TextInput
              label={t('newPassword')}
              value={newPassword}
              onChange={setNewPassword}
              type="password"
              required
            />
            <TextInput
              label={t('repeatPassword')}
              value={repeatPassword}
              onChange={setRepeatPassword}
              type="password"
              required
            />
            {error && <div className="text-red-500 text-center">{error}</div>}
            {message && <div className="text-red-500 text-center">{message}</div>}
            <TextButton disabled={status === 'loading'} type="submit">
              {t('submitButton', { context })}
            </TextButton>
          </>
        ) : (
          <div className="card flex flex-col items-center gap-y-6">
            <h1 className="text-3xl md:mx-10 text-center">{t('invalidToken')}</h1>
            <Link to="/forgot" className="link text-center">
              {t('linkForgot', { context })}
            </Link>
          </div>
        )}
        <Link to="/login" className="link text-center">
          {t('linkLogin', { context })}
        </Link>
      </form>
    </div>
  );
};

export default ResetPassword;
