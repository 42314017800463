import { useTranslation } from 'react-i18next';
import { QueryStatus } from '@reduxjs/toolkit/query';
import EntityTable from '../components/EntityTable.component';
import { useAppSelector } from '../store';
import { useGetProfileQuery } from '../store/api/profile.api';
import { useGetUsersQuery } from '../store/api/user.api';
import { useGetMyPermissionsQuery } from '../store/api/permissions.api';
import Permissions from '../model/permissions.model';

const Users = () => {
  const { t } = useTranslation('home');
  const { selectedDivision } = useAppSelector((state) => state.profileSlice);
  const { data: profile } = useGetProfileQuery(null);
  const { data: usersPage, status: usersStatus } = useGetUsersQuery({
    divisionIds: selectedDivision?.id ? [selectedDivision.id] : [-1],
  });
  const { data: permissions, status: permissionsStatus } = useGetMyPermissionsQuery(
    selectedDivision?.id ?? -1,
  );

  if (usersStatus === QueryStatus.pending || permissionsStatus === QueryStatus.pending) {
    return <div>{t('loading')}</div>;
  }

  const data = usersPage?.results.map((user) => ({
    id: user.id,
    name: user.username,
    email: user.email,
    division: user.division?.name ?? '-',
  }));

  if (!profile?.user) {
    return null;
  }

  return (
    <EntityTable
      title={t('title')}
      headers={[t('table.headers.name'), t('table.headers.email'), t('table.headers.division')]}
      data={data}
      growingColumnIndex={0}
      permissions={{
        add: () => permissions?.some((permission) => permission === Permissions.CREATE_USER),
        edit: () => permissions?.some((permission) => permission === Permissions.EDIT_USER),
        delete: () => permissions?.some((permission) => permission === Permissions.DELETE_USER),
        roles: (item) =>
          permissions?.some(
            (permission) => permission === Permissions.EDIT_USER && item.id !== profile.user?.id,
          ),
      }}
    />
  );
};

export default Users;
