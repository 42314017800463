import { api } from '.';
import Permissions from '../../model/permissions.model';

const permissionApi = api.injectEndpoints({
  endpoints: (build) => ({
    getMyPermissions: build.query<Permissions[], number>({
      query: (divisionId) => ({
        url: `/v1/permissions/me?divisionId=${divisionId}`,
        method: 'GET',
      }),
      providesTags: ['permission'],
    }),
  }),
});

export const { useGetMyPermissionsQuery } = permissionApi;

export const {
  endpoints: { getMyPermissions },
} = permissionApi;
