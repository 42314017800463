import { Navigate, Outlet } from 'react-router-dom';
import { QueryStatus } from '@reduxjs/toolkit/query';
import { useTranslation } from 'react-i18next';
import { useGetProfileQuery } from '../store/api/profile.api';
import { useGetMyPermissionsQuery } from '../store/api/permissions.api';
import Permissions from '../model/permissions.model';

const PrivateRoute = ({ requiredPermission }: { requiredPermission?: Permissions }) => {
  const { t } = useTranslation('common');
  const { data: profile, status: profileStatus } = useGetProfileQuery(null);
  const { data: userPermissions, status: permissionsStatus } = useGetMyPermissionsQuery(
    profile?.user?.division_id ?? -1,
  );

  const isLoggedIn = profileStatus === QueryStatus.fulfilled && !!profile?.user;
  const isAuthenticated =
    !requiredPermission ||
    !!(
      permissionsStatus === QueryStatus.fulfilled &&
      userPermissions?.find((userPermission) => userPermission === requiredPermission)
    );
  const isLoading =
    profileStatus === QueryStatus.pending || permissionsStatus === QueryStatus.pending;

  if (isLoading) {
    return <div>{t('loading')}</div>;
  }

  if (!isLoggedIn) {
    return <Navigate to="/login" />;
  }

  if (!isAuthenticated) {
    return <Navigate to="/unauthorized" />;
  }

  return <Outlet />;
};

export default PrivateRoute;
