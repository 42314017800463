import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import Modal from './Modal.component';
import TextButton from './TextButton.component';
import { useAppSelector } from '../store';
import warning from '../assets/warning-1-svgrepo-com.svg';
import { useDeleteUserMutation } from '../store/api/user.api';

const DeleteUserModal = () => {
  const { t } = useTranslation('home');
  const [modalActive, setModalActive] = useState(true);
  const [deleteUser] = useDeleteUserMutation();
  const params = useParams();
  const { users } = useAppSelector((state) => state.usersSlice);
  const user = users?.find((u) => u.id === parseInt(params.id ?? '-1', 10));
  const [error, setError] = useState('');
  const context = 'delete';
  const shouldClose = !params.id || Number.isNaN(parseInt(params.id, 10)) || !user;
  useEffect(() => {
    if (shouldClose) {
      setModalActive(false);
    }
  }, [params, user]);

  if (shouldClose) {
    return null;
  }

  return (
    <Modal isActive={modalActive} header={t('modal.header', { context })}>
      {shouldClose || !user ? null : (
        <form
          className="flex flex-col justify-between flex-1"
          onSubmit={(event) => {
            event.preventDefault();
            // assignment on a separate row to avoid ts-expect-error affecting more than just the id
            // @ts-expect-error params is defined here, as per the assertion
            const id = parseInt(params.id, 10);
            deleteUser(id)
              .unwrap()
              .then(() => {
                setModalActive(false);
              })
              .catch(() => {
                setError(t('modal.error', { context }));
              });
          }}
        >
          <div className="flex flex-col gap-2">
            <ul>
              <li>{user.username}</li>
              <li>{user.email}</li>
            </ul>
            <div className="flex gap-2 justify-between">
              {t('modal.areYouSure', { context, name: user.username })}
              <img src={warning} alt={t('iconsAlt.warning')} height={24} width={24} />
            </div>
            {error && <div className="text-rose-500">{error}</div>}
          </div>
          <TextButton type="submit">{t('modal.submit', { context })}</TextButton>
        </form>
      )}
    </Modal>
  );
};

export default DeleteUserModal;
