import { MouseEventHandler } from 'react';

const TD = ({
  children,
  onClick,
  className,
}: {
  children?: React.ReactNode;
  onClick?: MouseEventHandler<HTMLTableCellElement>;
  className?: string;
}) => (
  <td tabIndex={onClick ? 0 : undefined} className={`px-4 py-2 ${className}`} onClick={onClick}>
    {children}
  </td>
);

export default TD;
