import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import closeIcon from '../assets/close-svgrepo-com.svg';
import IconButton from './IconButton.component';
import useEscapeKey from '../hooks/useEscapeKey.hook';
import useOutsideClick from '../hooks/useOutsideClick';
import useScrollDisable from '../hooks/useScrollDisable';

type AnimationClass = 'animate-slide-in-right' | 'animate-slide-out-right';

const animationDurationMS = 200;

const Modal = ({
  children,
  onClose,
  isActive,
  setActive,
  footer,
  header,
}: {
  children: React.ReactNode;
  onClose?: () => void;
  isActive?: boolean;
  setActive?: (active: boolean) => void;
  footer?: React.ReactNode;
  header?: string;
}) => {
  const { t } = useTranslation('common');
  const navigate = useNavigate();

  const [animationClass, setAnimationClass] = useState<AnimationClass | undefined>(undefined);

  const modalRef = useRef<HTMLDivElement>(null);
  const didRedirect = useRef(false);

  useEffect(() => {
    if (!isActive) {
      close();
    } else {
      setAnimationClass('animate-slide-in-right');
    }
  }, [isActive]);

  const close = () => {
    setAnimationClass('animate-slide-out-right');
    onClose?.();
    setTimeout(() => {
      if (didRedirect.current) return;
      didRedirect.current = true;
      navigate(-1);
    }, animationDurationMS);
    setActive?.(false);
  };

  useEscapeKey(close);
  useOutsideClick(close, modalRef);
  useScrollDisable(isActive);

  return (
    <div
      className={`absolute inset-0 flex justify-end align-middle bg-zinc-950 z-40 bg-opacity-0 ${isActive ? 'animate-transition-bg-opacity-in' : 'animate-transition-bg-opacity-out'}`}
    >
      <div
        ref={modalRef}
        className={`bg-slate-100 h-full absolute text-black translate-x-full ${animationClass ?? ''} px-8 py-8 flex-col flex z-50 justify-between lg:max-w-[33%] md:max-w-[50%] sm:max-w-full min-w-[20%]`}
      >
        <div className="flex flex-col flex-1">
          <div className="flex justify-between gap-1">
            {header && <div className="text-lg">{header}</div>}
            <IconButton
              onClick={close}
              src={closeIcon}
              alt={t('iconsAlt.close')}
              className="translate-x-1/4 -translate-y-1/4"
            />
          </div>
          {children}
        </div>
        {footer && <div className="mt-2 flex flex-col">{footer}</div>}
      </div>
    </div>
  );
};

export default Modal;
