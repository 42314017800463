import { createSlice } from '@reduxjs/toolkit';
import { divisionsApi } from './api/divisions.api';
import DivisionsState from '../model/state/divisionsState.model';
import { LOGOUT_ACTION_TYPE } from './helpers/logout.action';

const initialState: DivisionsState = {
  status: 'idle',
  divisions: null,
};

const slice = createSlice({
  name: 'divisionsSlice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(LOGOUT_ACTION_TYPE, () => initialState)
      .addMatcher(divisionsApi.endpoints.getDivisions.matchPending, (state) => {
        state.status = 'loading';
      })
      .addMatcher(divisionsApi.endpoints.getDivisions.matchFulfilled, (state, action) => {
        state.divisions = action.payload;
        state.status = 'succeeded';
      })
      .addMatcher(divisionsApi.endpoints.getDivisions.matchRejected, (state) => {
        state.status = 'failed';
      })
      .addMatcher(divisionsApi.endpoints.addDivision.matchPending, (state) => {
        state.status = 'loading';
      })
      .addMatcher(divisionsApi.endpoints.addDivision.matchFulfilled, (state, action) => {
        state.divisions = [...(state.divisions || []), action.payload];
        state.status = 'succeeded';
      })
      .addMatcher(divisionsApi.endpoints.addDivision.matchRejected, (state) => {
        state.status = 'failed';
      })
      .addMatcher(divisionsApi.endpoints.editDivision.matchPending, (state) => {
        state.status = 'loading';
      })
      .addMatcher(divisionsApi.endpoints.editDivision.matchFulfilled, (state, action) => {
        state.divisions =
          state.divisions?.map((division) =>
            division.id === action.payload.id ? action.payload : division,
          ) ?? [];
        state.status = 'succeeded';
      })
      .addMatcher(divisionsApi.endpoints.editDivision.matchRejected, (state) => {
        state.status = 'failed';
      })
      .addMatcher(divisionsApi.endpoints.deleteDivision.matchPending, (state) => {
        state.status = 'loading';
      })
      .addMatcher(divisionsApi.endpoints.deleteDivision.matchFulfilled, (state, action) => {
        state.divisions =
          state.divisions?.filter((division) => division.id !== action.meta.arg.originalArgs) ?? [];
        state.status = 'succeeded';
      })
      .addMatcher(divisionsApi.endpoints.deleteDivision.matchRejected, (state) => {
        state.status = 'failed';
      });
  },
});

export default slice.reducer;
