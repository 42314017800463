/* The array is static */
/* eslint-disable react/no-array-index-key */
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import logo from '../assets/logo.svg';

const Drawer = ({ links }: { links: { to: string; labelKey: string; iconSource: string }[] }) => {
  const { t } = useTranslation('common');
  return (
    <div className="bg-slate-700 flex flex-col items-stretch">
      <div className="flex p-8 !w-24 !h-24 box-content">
        <img src={logo} alt={t('iconsAlt.logo')} className="!w-24 absolute" />
      </div>
      {links.map((link, index) => {
        return (
          <Link
            key={index}
            to={link.to}
            className="text-white hover:text-slate-200 hover:bg-opacity-10 bg-black bg-opacity-0 flex justify-start items-center gap-2 p-3 text-lg"
          >
            <img src={link.iconSource} alt={link.labelKey} className="w-8" />
            {t(link.labelKey)}
          </Link>
        );
      })}
    </div>
  );
};

export default Drawer;
