/* eslint-disable react/jsx-props-no-spreading */

import { useTranslation } from 'react-i18next';
import { MultiValueRemoveProps, components } from 'react-select';
import close from '../../assets/close-svgrepo-com.svg';

const MultiValueRemove = (props: MultiValueRemoveProps<{ label: string; value: string }>) => {
  const { t } = useTranslation('common');
  return (
    <components.MultiValueRemove {...props}>
      <img src={close} alt={t('iconsAlt.removeOption')} height={12} width={12} />
    </components.MultiValueRemove>
  );
};

export default MultiValueRemove;
