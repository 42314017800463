import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { QueryStatus } from '@reduxjs/toolkit/query';
import { useGetUserQuery } from '../store/api/user.api';
import profileDefault from '../assets/profile-default-svgrepo-com.svg';
import { useGetUserRolesQuery } from '../store/api/userRoles.api';

const User = () => {
  const { id } = useParams();
  const { t } = useTranslation('user');
  const { data: user, status: userStatus } = useGetUserQuery(parseInt(id || '-1', 10));
  const { data: userRoles, status: userRolesStatis } = useGetUserRolesQuery(
    parseInt(id || '-1', 10),
  );

  const divisions =
    userRoles
      ?.map((role) => role.division)
      .filter((division, index, array) => array.indexOf(division) === index) ?? [];

  if (userStatus === QueryStatus.pending || userRolesStatis === QueryStatus.pending) {
    return <div>{t('loading')}</div>;
  }

  if (!id || !user) {
    return <div>{t('noId')}</div>;
  }

  return (
    <div className="flex-1 flex gap-4 items-start justify-center card">
      <img
        src={user.image_url ?? profileDefault}
        alt={t('iconsAlt.profilePicture')}
        className="md:max-w-[240px]"
      />
      <div className="flex-grow flex flex-col gap-2">
        <div className="text-bold text-2xl leading-8">{user.username}</div>
        {user?.info && (
          <div>
            <div className="text-bold">{t('about')}</div>
            <div>{user.info}</div>
          </div>
        )}
        {userRoles && (
          <div className="flex flex-col">
            <div className="text-bold">{t('rolesTitle')}</div>
            <table className="table-auto text-left border-collapse relative flex-1">
              <thead className="relative">
                <div className="bg-slate-400 absolute inset-0 rounded-t-sm -z-0" />
                <tr className="relative">
                  <th className="p-1 z-10">{t('division')}</th>
                  <th className="p-1 z-10">{t('role')}</th>
                </tr>
              </thead>
              <tbody>
                {divisions.map((division) => (
                  <tr className="border-y border-t-transparent border-b-slate-300 last:border-b-0">
                    <td className="p-1">{division.name}</td>
                    <td className="p-1">
                      {userRoles
                        .filter((role) => role.division.id === division.id)
                        .map((role) => (
                          <div key={role.id}>{role.role.name}</div>
                        ))}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </div>
  );
};

export default User;
