import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { QueryStatus } from '@reduxjs/toolkit/query';
import { useGetDivisionQuery } from '../store/api/divisions.api';
import { useGetUsersQuery } from '../store/api/user.api';

const Division = () => {
  const id = parseInt(useParams().id || '-1', 10);
  const { t } = useTranslation(['division', 'roles']);
  const { data: division, status: divisionStatus } = useGetDivisionQuery(id);
  const { data: users, status: usersStatus } = useGetUsersQuery({ divisionIds: [id] });

  if (divisionStatus === QueryStatus.pending || usersStatus === QueryStatus.pending) {
    return <div>{t('loading')}</div>;
  }

  if (!id || !division) {
    return <div>{t('noId')}</div>;
  }

  return (
    <div className="flex-1 flex gap-4 items-start justify-center card">
      <div className="flex-grow flex flex-col gap-4">
        <div className="text-bold text-2xl leading-8">{division.name}</div>
        {division?.info && (
          <div>
            <div className="text-bold">{t('about')}</div>
            <div>{division.info}</div>
          </div>
        )}
        {division.children && division.children.length > 0 && (
          <div className="flex-1 flex flex-col">
            <div className="text-bold">{t('divisionsTitle')}</div>
            <table className="table-auto text-left border-collapse relative flex-1 text-sm">
              <thead className="relative text-md text-white">
                <div className="bg-slate-500 absolute inset-0 rounded-t-sm -z-0" />
                <tr className="relative">
                  <th className="p-1 z-10">{t('divisionName')}</th>
                </tr>
              </thead>
              <tbody>
                {division.children.map((child) => (
                  <tr key={child.id} className="border-y border-slate-700 last:border-b-0">
                    <td>{child.name}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
        {users?.results && users.results.length > 0 && (
          <div className="flex-1 flex flex-col">
            <div className="text-bold">{t('usersTitle', { division: division.name })}</div>
            <table className="table-auto text-left border-collapse relative flex-1">
              <thead className="relative text-white">
                <div className="bg-slate-500 absolute inset-0 rounded-t-sm -z-0" />
                <tr className="relative">
                  <th className="p-1 z-10">{t('username')}</th>
                  <th className="p-1 z-10">{t('roles')}</th>
                </tr>
              </thead>
              <tbody>
                {users.results.map((user) => (
                  <tr key={user.id} className="text-sm">
                    <td>{user.username}</td>
                    <td>
                      {user.user_roles.length > 0
                        ? user.user_roles.map((user_role) =>
                            t(`roles:${user_role.role.name}`, 'roles:none'),
                          )
                        : t('roles:none')}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </div>
  );
};

export default Division;
