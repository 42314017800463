import { api } from '.';
import Role from '../../model/role.model';

export const rolesApi = api.injectEndpoints({
  endpoints: (build) => ({
    getRoles: build.query<Role[], null>({
      query: () => ({
        url: `/v1/roles`,
        method: 'GET',
      }),
      providesTags: ['role'],
    }),
  }),
});

export const { useGetRolesQuery } = rolesApi;

export const {
  endpoints: { getRoles },
} = rolesApi;
