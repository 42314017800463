import { HTMLInputTypeAttribute, useId, useState } from 'react';

const TextInput = ({
  label,
  onChange,
  required,
  value,
  type,
  onFocus,
  onBlur,
}: {
  label?: string;
  onChange?: (value: string) => void;
  required?: boolean;
  value?: string;
  type?: HTMLInputTypeAttribute;
  onFocus?: () => void;
  onBlur?: () => void;
}) => {
  const id = useId();
  const [isFocused, setIsFocused] = useState(false);
  const [innerValue, setInnerValue] = useState(value);
  return (
    <div className="flex flex-col items-stretch relative">
      <label
        htmlFor={id}
        className={`z-10 absolute h-1/2 flex justify-start items-center transition-all ${isFocused || innerValue ? '-translate-y-full' : ''}`}
      >
        <div
          className={`z-10 inset-0 p-2 absolute flex items-center transition-all translate-y-1/2 ${isFocused || innerValue ? 'text-2xs' : 'leading-none'} ease-out`}
        >
          {`${label}${required ? '*' : ''}`}
        </div>
      </label>
      <input
        onFocus={() => {
          onFocus?.();
          setIsFocused(true);
        }}
        onBlur={() => {
          onBlur?.();
          setIsFocused(false);
        }}
        required={required}
        type={type ?? 'text'}
        id={id}
        className="px-1 py-1 text-black bg-slate-300 bg-opacity-50 border-none shadow-none rounded-sm min-h-8 text-sm focus:outline-none"
        onChange={(event) => {
          onChange?.(event.target.value);
          setInnerValue(event.target.value);
        }}
        value={value}
      />
    </div>
  );
};

export default TextInput;
